<template>
    <div v-if="role" class="add-new-role">
        <title-bar
            submitBtn
            :submitBtnTitle="$t('messages.roleUpdate')"
            :title="$t('messages.roleUpdate')"
            @submitPressed="submitPressed"
        />
        <b-card no-body>
            <b-tabs v-model="tabIndex" card content-class="mt-3">
                <b-tab :title="$t('forms.overview')" active>
                    <role-form
                        :roleObject="role"
                        :action="action"
                        @clearAction="action = null"
                    />
                </b-tab>
                <b-tab title="Employees">
                    <users :roleObject="role" type="employees"/>
                </b-tab>
                <b-tab title="Customers">
                    <users :roleObject="role" type="customers"/>
                </b-tab>
            </b-tabs>
        </b-card>
    </div>
</template>

<script>
import TitleBar from "@/components/TitleBar.vue";
import RoleForm from "./components/RoleForm.vue";
import Users from "./components/Users.vue";

export default {
    components: {
        TitleBar,
        RoleForm,
        Users
    },
    created() {
        this.load();
    },
    data() {
        return {
            action: null,
            role: null,
        };
    },
    methods: {
        load() {
            const id = this.$route.params.id;
            this.$Roles.getResource({id}).then((response) => {
                this.role = response.data;
            });
        },
        submitPressed() {
            this.action = "update";
        },
    },
};
</script>
